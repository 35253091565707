import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "../components/grid"
import styles from "../styles/pages/news.module.scss"
import pageStyles from "../styles/pages/page-styles.module.scss"
import { slugify } from "../variables"

const NewsArticle = props => {
  let { title, dateString, summary, altSummary, hideDate } = props
  if (summary === null) {
    summary = altSummary.childMarkdownRemark.excerpt
  } else {
    summary = summary.summary
  }
  return (
    <div className={styles.newsArticleItem}>
      <Link className={styles.newsArticle} to={`/news/${slugify(title)}/`}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.date}>{hideDate ? "" : dateString}</p>
        <p className={styles.summary}>{summary}</p>
      </Link>
    </div>
  )
}

class News extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      numArticles: 0,
      allArticlesLoaded: false,
    }
    this.pageLength = 8
  }

  componentDidMount() {
    this.loadMore()
  }

  loadMore = () => {
    let totalNumArticles = this.props.data.contentfulNewsPage.newsArticles
      .length
    this.setState(prevState => {
      // bounds the number of pages we're loading by the length
      // of the articles array
      let newNumArticles = Math.min(
        prevState.numArticles + this.pageLength,
        totalNumArticles
      )
      return {
        numArticles: newNumArticles,
        allArticlesLoaded: totalNumArticles <= newNumArticles,
      }
    })
  }

  render() {
    let articles = this.props.data.contentfulNewsPage.newsArticles
    articles = articles
      .sort((a, b) => {
        let dateA = new Date(a.dateNum)
        let dateB = new Date(b.dateNum)
        return dateB - dateA
      })
      .slice(0, this.state.numArticles)
    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="News" />
        <div className={pageStyles.pageContentContainer}>
          <h2 className={styles.newsPageTitle}>News</h2>
          <div className={styles.gridContainer}>
            <div className={styles.content}>
              <Grid forwardRef={this.ref} news maxColumns={2}>
                {articles.map(article => (
                  <NewsArticle {...article} key={article.id} />
                ))}
              </Grid>
              {!this.state.allArticlesLoaded && !this.state.loadMore && (
                <div className={styles.loadMoreWrapper}>
                  <button onClick={this.loadMore}>Load more</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default News

export const query = graphql`
  query {
    contentfulNewsPage(name: { eq: "News Page" }) {
      newsArticles {
        dateString: date(formatString: "MM/DD/YYYY")
        dateNum: date
        hideDate
        title
        id
        altSummary: articleText {
          childMarkdownRemark {
            excerpt(format: PLAIN, pruneLength: 180)
          }
        }
        summary {
          summary
        }
      }
    }
  }
`
